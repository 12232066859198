import React from 'react';
import dogblue from '../../../assets/images/rapidNaming/de/dog-blue.png';
import dogbrown from '../../../assets/images/rapidNaming/de/dog-brown.png';
import doggreen from '../../../assets/images/rapidNaming/de/dog-green.png';
import doggrey from '../../../assets/images/rapidNaming/de/dog-grey.png';
import dogred from '../../../assets/images/rapidNaming/de/dog-red.png';
import dogyellow from '../../../assets/images/rapidNaming/de/dog-yellow.png';

import doorblue from '../../../assets/images/rapidNaming/de/door-blue.png';
import doorbrown from '../../../assets/images/rapidNaming/de/door-brown.png';
import doorgreen from '../../../assets/images/rapidNaming/de/door-green.png';
import doorgrey from '../../../assets/images/rapidNaming/de/door-grey.png';
import doorred from '../../../assets/images/rapidNaming/de/door-red.png';
import dooryellow from '../../../assets/images/rapidNaming/de/door-yellow.png';

import moonblue from '../../../assets/images/rapidNaming/de/moon-blue.png';
import moonbrown from '../../../assets/images/rapidNaming/de/moon-brown.png';
import moongreen from '../../../assets/images/rapidNaming/de/moon-green.png';
import moongrey from '../../../assets/images/rapidNaming/de/moon-grey.png';
import moonred from '../../../assets/images/rapidNaming/de/moon-red.png';
import moonyellow from '../../../assets/images/rapidNaming/de/moon-yellow.png';

import shoeblue from '../../../assets/images/rapidNaming/de/shoe-blue.png';
import shoebrown from '../../../assets/images/rapidNaming/de/shoe-brown.png';
import shoegreen from '../../../assets/images/rapidNaming/de/shoe-green.png';
import shoegrey from '../../../assets/images/rapidNaming/de/shoe-grey.png';
import shoered from '../../../assets/images/rapidNaming/de/shoe-red.png';
import shoeyellow from '../../../assets/images/rapidNaming/de/shoe-yellow.png';

import treeblue from '../../../assets/images/rapidNaming/de/tree-blue.png';
import treebrown from '../../../assets/images/rapidNaming/de/tree-brown.png';
import treegreen from '../../../assets/images/rapidNaming/de/tree-green.png';
import treegrey from '../../../assets/images/rapidNaming/de/tree-grey.png';
import treered from '../../../assets/images/rapidNaming/de/tree-red.png';
import treeyellow from '../../../assets/images/rapidNaming/de/tree-yellow.png';

export const rnImages = [
	[dogred, shoered, treered, doorred, moonred],
	[dogblue, shoeblue, treeblue, doorblue, moonblue],
	[doggreen, shoegreen, treegreen, doorgreen, moongreen],
	[dogyellow, shoeyellow, treeyellow, dooryellow, moonyellow],
	[dogbrown, shoebrown, treebrown, doorbrown, moonbrown],
];

export const ranIntroItemImages = [
	doggrey,
	shoegrey,
	treegrey,
	doorgrey,
	moongrey,
];
export const ranIntroImages = [
	dogred,
	shoeblue,
	treegreen,
	dooryellow,
	moonbrown,
];

export const WMWAnswers = [
	['Ball', 'Tisch'],
	['Fuß', 'Band'],
	['Buch', 'Haus ', 'Dampf'],
	['Hund', 'Schuh', 'Salz'],
	['Wind', 'Tür', 'Hut', 'Stein'],
	['Feld', 'Rock', 'Bär', 'Kopf'],
	['Wald', 'Kind', 'Hand', 'Schaf', 'Bild'],
	['Kuh', 'Holz', 'Stift', 'Boot', 'Maus'],
	['Mond', 'Reh', 'Bett', 'Keks', 'Dach', 'Bus'],
	['Baum', 'Fisch', 'Zahn', 'Luft', 'Arm', 'Pferd'],
	/* ['Stern', 'Pilz', 'Reis', 'Haut', 'Laub', 'Stuhl', 'Rad'],
	['Stock', 'Kern', 'Mund', 'Wolf', 'Haar', 'Ast', 'Uhr'], */
];

export const WMNAnswers = [
	[3, 7],
	[2, 9],
	[1, 5, 8],
	[2, 4, 9],
	[3, 4, 8, 1],
	[6, 2, 7, 3],
	[1, 2, 7, 3, 5],
	[9, 6, 3, 1, 4],
	[8, 7, 2, 5, 9, 1],
	[1, 4, 2, 7, 9, 3],
	/* [2, 5, 3, 8, 4, 9, 1],
	[6, 9, 7, 1, 4, 7, 2], */
];

export const WMNTrialAnswers = [
	[1, 8],
	[5, 6],
	[8, 9, 2],
];
export const WMWTrialAnswers = [
	['Glas', 'Nacht'],
	['Tag', 'Heft'],
];

export const WMWTrialAnswersChild = [
	['Glas', 'Nacht'],
	['Tag', 'Heft'],
	['Huhn', 'Weg', 'Freund'],
];

export const SpoonerismAnswers = [
	{task: 'Buch - Lied', correct: 'Luch - Bied'},
	{task: 'Insel - Arm', correct: 'Ansel - Irm'},
	{task: 'Wal - Turm', correct: 'Tal - Wurm'},
	{task: 'Hase - Regen', correct: 'Rase - Hegen'},
	{task: 'Onkel - Imker', correct: 'Inkel - Omker'},
	{task: 'Zange – Wahl', correct: 'Wange – Zahl'},
	{task: 'Unmut – Anfall', correct: 'Anmut – Unfall'},
	{task: 'Fisch – Löwe', correct: 'Lisch – Föwe'},
	{task: 'Bauch – Sonne', correct: 'Sauch – Bonne'},
	{task: 'Mohn – Tasche', correct: 'Tohn – Masche'},
	{task: 'Achse – Ort', correct: 'Ochse – Art'},
	{task: 'Apfel – Obst', correct: 'Opfel – Abst'},
	{task: 'Liebe – Hose', correct: 'Hiebe – Lose'},
	{task: 'Enkel - Ober', correct: 'Onkel - Eber'},
	{task: 'Baum – Sand', correct: 'Saum – Band'},
	{task: 'Rind – Wand', correct: 'Wind – Rand'},
	{task: 'Angel – Effekt', correct: 'Engel – Affekt'},
	{task: 'Katze – Hund', correct: 'Hatze – Kund'},
	{task: 'Esel – Anker', correct: 'Asel – Enker'},
	{task: 'Ostern - Amen', correct: 'Astern - Omen'},
];

export const SpoonerismIntroTasks = [
	{task: 'Tonne - Wanne', correct: 'Wonne - Tanne'},
	{task: 'Imker - Adler', correct: 'Amker - Idler'},
	{task: 'Schuh - Kahl', correct: 'Kuh – Schal'},
];

export const phonemeIsolationIntro = [
	{
		id: 0,
		sound: 'g',
		options: {
			a: 'ball',
			b: 'sweater',
			c: 'fish',
			d: 'ski',
		},
		correct: 'b',
	},
	{
		id: 1,
		sound: 'h',
		options: {
			a: 'telephone',
			b: 'flower',
			c: 'motorcycle',
			d: 'house',
		},
		correct: 'd',
	},
];

export const phonemeIsolationTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'c',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'i',
		options: {
			a: 'icecream',
			b: 'chair',
			c: 'baby',
			d: 'horse',
		},
		correct: 'a',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'b',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'd',
		type: 'starts_with_',
	},
	{
		id: 5,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'b',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 6,
		options: {
			a: 'cup',
			b: 'chair',
			c: 'horse',
			d: 'book',
		},
		correct: 'a',
		sound: 'p',
		type: 'ends_with_',
	},
];
export const phonemeIsolationNormingTasks = [
	{
		id: 0,
		sound: 'b',
		options: {
			a: 'apple',
			b: 'bicycle',
			c: 'book',
			d: 'tractor',
		},
		correct: 'book',
		type: 'starts_with_',
	},
	{
		id: 1,
		sound: 'l',
		options: {
			a: 'foot',
			b: 'doll',
			c: 'nose',
			d: 'lion',
		},
		correct: 'lion',
		type: 'starts_with_',
	},
	{
		id: 2,
		sound: 'k',
		options: {
			a: 'shoe',
			b: 'cat',
			c: 'flower',
			d: 'plane',
		},
		correct: 'cat',
		type: 'starts_with_',
	},
	{
		id: 3,
		sound: 'e',
		options: {
			a: 'elephant',
			b: 'spoon',
			c: 'bed',
			d: 'fish',
		},
		correct: 'elephant',
		type: 'starts_with_',
	},
	{
		id: 4,
		sound: 'i',
		options: {
			a: 'horse',
			b: 'chair',
			c: 'baby',
			d: 'icecream',
		},
		correct: 'icecream',
		type: 'starts_with_',
	},
	{
		id: 5,
		sound: 'v',
		options: {
			a: 'rabbit',
			b: 'gloves',
			c: 'tractor',
			d: 'boot',
		},
		correct: 'gloves',
		type: 'starts_with_',
	},
	{
		id: 6,
		sound: 'oe',
		options: {
			a: 'fireengine',
			b: 'ear',
			c: 'cup',
			d: 'teddy',
		},
		correct: 'ear',
		type: 'starts_with_',
	},
	{
		id: 7,
		sound: 's',
		options: {
			a: 'jeans',
			b: 'purse',
			c: 'key',
			d: 'bicycle',
		},
		correct: 'bicycle',
		type: 'starts_with_',
	},
	{
		id: 8,
		sound: 't',
		options: {
			a: 'foot',
			b: 'eye',
			c: 'sun',
			d: 'bed',
		},
		correct: 'foot',
		type: 'ends_with_',
	},
	{
		id: 9,
		options: {
			a: 'crocodile',
			b: 'shoe',
			c: 'ski',
			d: 'key',
		},
		correct: 'shoe',
		sound: 'o',
		type: 'ends_with_',
	},
	{
		id: 10,
		options: {
			a: 'jeans',
			b: 'banana',
			c: 'sweater',
			d: 'saft',
		},
		correct: 'banana',
		sound: 'n',
		type: 'ends_with_',
	},
	{
		id: 11,
		options: {
			a: 'tractor',
			b: 'fish',
			c: 'icecream',
			d: 'cat',
		},
		correct: 'tractor',
		sound: 'r',
		type: 'ends_with_',
	},
	{
		id: 12,
		options: {
			a: 'reflex',
			b: 'plane',
			c: 'book',
			d: 'plowtruck',
		},
		correct: 'plane',
		sound: 'y',
		type: 'ends_with_',
	},
	{
		id: 13,
		options: {
			a: 'chair',
			b: 'cup',
			c: 'horse',
			d: 'book',
		},
		correct: 'cup',
		sound: 'p',
		type: 'ends_with_',
	},
	{
		id: 14,
		options: {
			a: 'bicycle',
			b: 'package',
			c: 'finger',
			d: 'fireengine',
		},
		correct: 'package',
		sound: 'e',
		type: 'ends_with_',
	},
	{
		id: 15,
		options: {a: 'horse', b: 'cup', c: 'porrige', d: 'cow'},
		correct: 'cow',
		sound: 'u',
		type: 'ends_with_',
	},
];

export const oneMinuteTasks = [
	'Bad',
	'Licht',
	'gut',
	'da',
	'Schuh',
	'kaum',
	'doch',
	'Pferd',
	'tief',
	'wenn',
	'nah',
	'um',
	'Rat',
	'nach',
	'hell',
	'Bus',
	'wir',
	'klein',
	'Fisch',
	'neu',
	'in',
	'dick',
	'lieb',
	'mit',
	'Buch',
	'hoch',
	'dein',
	'nett',
	'er',
	'Hut',
	'haben',
	'nass',
	'auf',
	'leben',
	'du',
	'satt',
	'mein',
	'Kuh',
	'scheuen',
	'durch',
	'laut',
	'nehmen',

	'hier',
	'malen',
	'zu',
	'schustern',
	'mau',
	'weil',
	'loben',
	'wie',
	'toll',
	'auch',
	'jagen',

	'geben',
	'machen',
	'fit',
	'weit',
	'streichen',
	'sie',
	'unter',
	'walten',
	'schalten',

	'ich',
	'faul',
	'gehen',
	'halten',
	'Rose',
	'kopieren',

	'genau',
	'damit',
	'böse',
	'ohne',
	'radieren',
	'Katze',
	'polieren',
	'oder',
	'hager',
	'müde',
	'neben',
	'jedoch',
	'Dose',
	'über',
	'wenig',
	'nötigen',
	'Apfel',
	'hinter',
	'euer',
	'weise',
	'darum',
	'nächtigen',
	'Hase',
	'damit',
	'heiter',
	'kaputt',
	'locker',
	'mäßigen',
	'Reise',
	'unser',
	'erziehen',
	'woher',
	'edel',
	'Nase',
	'mürbe',
	'lecker',
	'heikel',
	'reinigen',
	'aber',
	'mager',
	'Kreide',
	'albern',
	'wieso',
	'niemand',
	'eckig',
	'düster',
	'Becher',
	'ergreifen',
	'warum',
	'trocken',
	'jemand',
	'belegen',
	'Hose',
	'studieren',
	'weshalb',
	'sauber',
	'vage',
	'kurieren',
	'deshalb',
	'probieren',
	'Gemüse',
	'honorieren',
	'Rakete',
	'Limonade',
	'operieren',
	'Papagei',
	'Schokolade',
	'animieren',
	'Polizei',
	'reservieren',
	'marschieren',
	'Mathematik',
	'Dalmatiner',
	'tapezieren',
	'Geschwister',
	'galoppieren',
	'Piraterie',
	'imitieren',
	'Geschichte',
	'Tomate',
	'regulieren',
	'Melone',
	'dekorieren',
	'Hausaufgaben',
	'Industrie',
	'Akademie',
	'motivieren',
	'Elefanten',
	'investieren',
	'Leichtathletik',
	'Rosine',
	'Nationalist',
];

export const oneMinuteChildTasks = [
	'Bad',
	'Bus',
	'Rose',
	'Hase',
	'Kuh',
	'Hose',
	'Nase',
	'Rat',
	'Dose',
	'Hut',
	'Pferd',
	'Fisch',
	'Apfel',
	'Reise',
	'Buch',
	'Katze',
	'Kreide',
	'Schuh',
	'gut',
	'hell',
	'dein',
	'haben',
	'Gemüse',
	'klein',
	'leben',
	'nehmen',
	'satt',
	'er',
	'streichen',
	'ohne',
	'Rakete',
	'lieb',
	'Rosine', 
	'Schokolade',
	'warum',
	'jagen',
	'Becher',
	'Tomate',
	'Elefant',
	'radieren',
	'deshalb',
	'Papagei',
	'lieb',
	'sauber',
	'Polizei',
	'eckig',
	'Melone',
	'scheuen',
	'niemand',
	'belegen',
	'aber',
	'Limonade',
	'reservieren',
	'wieso',
	'Riese',
	'mager',
	'darum',
	'probieren',
	'düster',
	'galoppieren',
	'kaputt',
	'Akademie',
	'dekorieren',
	'reinigen',
	'Dalmatiner',
	'über',
	'woher',
	'locker',
	'operieren',
	'Geschichte',
	'wenig',
	'Hausaufgaben',
	'hinter',
	'heikel',
	'Licht',
	'Geschwister',
	'da',
	'malen',
	'animieren',
	'Industrie',
	'wir',
	'schustern',
	'mürbe',
	'loben',
	'heiter',
	'weit',
	'schalten',
	'weise',
	'euer',
	'damit',
	'gehen',
	'unser',
	'greifen',
	'Banane',
	'hoch',
	'walten',
	'polieren',
	'genau',
	'in',
	'marschieren',
	'nach',
	'tapezieren',
	'halten',
	'ich',
	'kopieren',
	'dick',
	'albern',
	'imitieren',
	'Mathematik',
	'honorieren',
	];

export const lkAnswersNorming = [
	'O',
	'O',
	'T',
	'T',
	'W',
	'W',
	'H',
	'H',
	'C',
	'C',
	'E',
	'E',
	'Y',
	'Y',
	'M',
	'M',
	'B',
	'B',
	'Z',
	'Z',
	'Ø',
	'Ø',
	'V',
	'V',
	'K',
	'K',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'P',
	'P',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'Å',
	'Å',
	'Q',
	'Q',
	'U',
	'U',
	'X',
	'X',
	'A',
	'A',
];
export const lkAnswers = [
	'O',
	'O',
	'E',
	'E',
	'I',
	'I',
	'S',
	'S',
	'T',
	'T',
	'U',
	'U',
	'N',
	'N',
	'Å',
	'Å',
	'G',
	'G',
	'D',
	'D',
	'Æ',
	'Æ',
	'Q',
	'Q',
];
export const kdlkAnswers = [
	'H',
	'H',
	'E',
	'E',
	'M',
	'M',
	'B',
	'B',
	'G',
	'G',
	'R',
	'R',
	'S',
	'S',
	'Æ',
	'Æ',
	'F',
	'F',
	'I',
	'I',
	'J',
	'J',
	'L',
	'L',
	'D',
	'D',
	'N',
	'N',
	'U',
	'U',
];
export const pdIntroSteps = [
	{	id:1,
		task: 'Sage das Wort „Reis“.',
		expectedAnswer: 'Reis',
	},
	{
		id:2,
		task: <span>
		Wenn du das <span style={{color:'orange'}}>/r/</span> in „Reis“ weglässt, wird daraus das Wort „<span style={{color:'orange'}}>Eis</span>“</span>,
		expectedAnswer: 'Eis',
	},
	{
		id:3,
		task: 'Sage das Wort „Bauch“.',
		expectedAnswer: 'Bauch',
	},
	{
		id:4,
		task: <span>
			Wenn du das <span style={{color:'orange'}}>/ch/</span> in „Bauch“ weglässt, wird daraus das Wort „<span style={{color:'orange'}}>Bau</span>“
			</span>,
		expectedAnswer: 'Bau',
	},
	{
		id:5,
		task: 'Sage das Wort „Stau“.',
		expectedAnswer: 'Stau',
	},
	{
		id:6,
		task: <span>
			Wenn du das <span style={{color:'orange'}}>/t/</span> in „Stau“ weglässt, wird daraus das Wort „<span style={{color:'orange'}}>Schau</span>“
			</span>,
		expectedAnswer: 'Schau',
	},
];
export const pdSteps = [
	'Decke',
	'Saal',
	'Mund',
	'Haus',
	'Tor',
	'Riegel',
	'warm',
	'Mond',
	'rot',
	'Eis',
	'rechts',
	'alt',
	'Maus',
	'Glas',
	'Brot',
	'Knopf',
	'frisch',
	'Kran',
	'Graben',
	'trauen'
];
export const pdAnswers = [
	'Ecke',
	'Aal',
	'und',
	'aus',
	'Ohr',
	'Igel',
	'arm',
	'Mohn',
	'roh',
	'Ei',
	'Recht',
	'All',
	'Mau',
	'Gas',
	'Boot',
	'Kopf',
	'Fisch',
	'Kahn',
	'Gaben',
	'tauen'
];

export const wdAnswersNorming = [
	'mase',
	'fly',
	'dra',
	'kråka',
	'melk',
	'tær',
	'rikke',
	'gråt',
	'bake',
	'regne',
	'hyle',
	'takk',
	'hvordan',
	'verden',
	'ekkelt',
	'magisk',
	'ski',
	'leksefri',
	'historier',
	'flokk',
	'senger',
	'kanskje',
	'sjokolade',
	'hjul',
	'rundt',
	'sild',
	'skjelven',
	'godt',
	'kyllinger',
	'kjøtt',
	'smarttelefon',
	'fugl',
	'sykkelhjelm',
	'hjerte',
	'kjeveortoped',
	'blåbærsyltetøy',
	'biblioteket',
	'kjetting',
	'sannsynlighet',
	'abrakadabra',
];

export const wdAnswers = [
	'hvordan',
	'verden',
	'magisk',
	'ski',
	'leksefri',
	'flokk',
	'kanskje',
	'rundt',
	'kyllinger',
	'blåbærsyltetøy',
	'biblioteket',
	'sannsynlighet',
	'abrakadabra',
];

export const rnIntroSteps = [
	'Intro',
	'Colors',
	'Images',
	'Colors and Images',
	'Ready to start',
];

export const rnItems = ['dog', 'shoe', 'tree', 'door', 'moon'];
export const rnColors = ['red', 'blue', 'green', 'yellow', 'brown'];

export const rnAnswers = [
	[
		{color: 0, item: 0},
		{color: 1, item: 1},
		{color: 2, item: 2},
		{color: 0, item: 0},
		{color: 1, item: 3},
		{color: 3, item: 4},
		{color: 4, item: 0},
	],
	[
		{color: 2, item: 4},
		{color: 0, item: 2},
		{color: 3, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
		{color: 4, item: 1},
		{color: 1, item: 4},
	],
	[
		{color: 2, item: 2},
		{color: 0, item: 1},
		{color: 0, item: 3},
		{color: 4, item: 0},
		{color: 2, item: 0},
		{color: 1, item: 3},
		{color: 4, item: 2},
	],
	[
		{color: 3, item: 4},
		{color: 0, item: 3},
		{color: 1, item: 2},
		{color: 4, item: 1},
		{color: 1, item: 4},
		{color: 4, item: 1},
		{color: 3, item: 2},
	],
	[
		{color: 2, item: 4},
		{color: 0, item: 0},
		{color: 3, item: 0},
		{color: 2, item: 3},
		{color: 3, item: 0},
		{color: 2, item: 3},
		{color: 3, item: 4},
	],
	[
		{color: 0, item: 1},
		{color: 4, item: 2},
		{color: 2, item: 1},
		{color: 4, item: 3},
		{color: 3, item: 2},
		{color: 0, item: 3},
		{color: 4, item: 2},
	],
	[
		{color: 2, item: 4},
		{color: 1, item: 1},
		{color: 2, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
		{color: 4, item: 1},
		{color: 0, item: 2},
		{color: 1, item: 1},
	],
];

export const kdrnAnswers = [
	[
		{color: 0, item: 0},
		{color: 1, item: 1},
		{color: 2, item: 2},
		{color: 0, item: 0},
		{color: 1, item: 3},
		{color: 3, item: 4},
	],
	[
		{color: 4, item: 0},
		{color: 2, item: 4},
		{color: 0, item: 2},
		{color: 3, item: 0},
		{color: 3, item: 4},
		{color: 1, item: 3},
	],
	[
		{color: 4, item: 1},
		{color: 1, item: 4},
		{color: 2, item: 2},
		{color: 0, item: 1},
		{color: 0, item: 3},
		{color: 4, item: 0},
	],
	[
		{color: 2, item: 0},
		{color: 1, item: 3},
		{color: 4, item: 2},
		{color: 3, item: 4},
		{color: 0, item: 3},
		{color: 1, item: 2},
		{color: 4, item: 1},
	],
];
