import React, {Component} from 'react';
import './App.scss';

import TestAdmin from './pages/testadmin';
import TestUser from './pages/testuser';
import SelectRole from './pages/SelectRole';
import literateLogo from './assets/images/logo-white-2.png';
import EnvironmentBanner from './components/EnvironmentBanner';
import {ENVIRONMENT} from './constants';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roomKey: '',
			testKey: '',
			testKeyType: '',
			ageGroup: null,
			section: 'initial',
			failStart: false,
		};
	}

	onSelectRole = (role, key, testKeyType, adminEmail = null, ageGroup) => {
		this.detectFailStart(role);
		if (role === 'initial') {
			this.setState({
				section: 'initial',
				testKey: '',
				roomKey: '',
				adminEmail: '',
			});
		} else if (role === 'testadmin') {
			if( ageGroup === 'null'){
				ageGroup = null;
			}
			this.setState({
				section: 'testadmin',
				testKey: key,
				adminEmail,
				testKeyType,
				ageGroup,
			});
		} else if (role === 'testuser') {
			if( ageGroup === 'null'){
				ageGroup = null;
			}
			this.setState({
				section: 'testuser',
				roomKey: key,
				testKeyType,
				ageGroup,
			});
		}
	};

	detectFailStart(role) {
		if (role === 'initial') {
			this.setState({
				failStart: true,
			});
		}
	}
	render() {
		const {
			section,
			roomKey,
			testKey,
			testKeyType,
			adminEmail,
			ageGroup,
		} = this.state;
		return (
			<div className="App">
				<EnvironmentBanner environment={ENVIRONMENT} />
				{section === 'initial' && (
					<SelectRole
						onSelectRole={this.onSelectRole}
						failStart={this.state.failStart}
					/>
				)}
				{section === 'testadmin' && (
					<TestAdmin
						testKey={testKey}
						testKeyType={testKeyType}
						ageGroup={ageGroup}
						adminId={adminEmail}
						onSelectRole={this.onSelectRole}
					/>
				)}
				{section === 'testuser' && (
					<TestUser
						roomKey={roomKey}
						testKeyType={testKeyType}
						ageGroup={ageGroup}
						onSelectRole={this.onSelectRole}
					/>
				)}
				{section === 'redirect' && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							textAlign: 'center',
							alignItems: 'center',
							justifyContent: 'space-between',
							minHeight: '100vh',
						}}
					>
						<img
							src={literateLogo}
							alt="Literate"
							className="title__logo"
						/>
						<div>
							<h1>Siden er deaktivert</h1>
							<p>
								Literate-child er nå deaktivert til fordel for
								vår nye normeringsside som gjelder for alt fra
								barnehage til voksne. <br />
								Du kan fortsatt bruke den nye siden på{' '}
								<a href="https://literate-normering.herokuapp.com">
									Literate normering
								</a>
							</p>
							<p>
								<a href="https://literate-normering.herokuapp.com">
									<button
										style={{
											fontSize: 24,
											padding: '20px 40px',
										}}
									>
										Gå til ny normeringsside
									</button>
								</a>
							</p>
						</div>
						<div></div>
						<div></div>
					</div>
				)}
			</div>
		);
	}
}

export default App;
